import React from 'react'
import { Footer } from './components/footer/Footer'
import { AppRouter } from './routes/AppRouter'

export const App = () => {

    return (
        <div>
            <AppRouter />
            <div className="mt-5">
                <Footer />
            </div>
        </div>
    )
}
