import React from 'react'
import msn from '../../assets/messenger.png'

export const Messenger = () => {
    return (
        <>
            <div className="btn-messenger">
                <a href="https://m.me/Econotransla" target="_blank">
                    <img className="img-fluid" src={msn} alt="messenger" />
                </a>
            </div>
        </>
    )
}
