import React from 'react'
import foto from '../../assets/logo-letras.png'
import mail1 from '../../assets/mail1.png'
import direccion1 from '../../assets/direccion1.png'
import phone1 from '../../assets/phone1.png'

import './contact.css'

export const Contact = () => {
    return (
        <div className='mt-5'>
            <h6 className="repit">Fletes y Mudanzas - Todo Destino</h6>
            <div >
                <div className='text-center'>
                    <a className='title'><img className="letras-contact" src={foto} alt="..." /> Contacto</a>
                </div>
            </div>

            <div className='row mb-5 text-center'>

                <div className='col-md-6 col-sm-12 col-xs-12 text-start mt-5'>
                    <h3 className='mb-5'>Mantengamos La Comunicación</h3>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3 col-sm-3 col-xs-6'>
                                <img src={direccion1}
                                    className="img-fluid mx-2 mb-1" alt="rpa" />
                            </div>
                            <div className='col-md-9 col-sm-9 col-xs-6'>
                                <h4> Visitanos!</h4>
                                <h3> Los Ángeles, Chile.</h3>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3 col-sm-3 col-xs-3'>
                                <img src={mail1}
                                    className="img-fluid mx-2 mb-1" alt="rpa" />
                            </div>
                            <div className='col-md-9 col-sm-9 col-xs-9'>
                                <h4> Contactanos!</h4>
                                <h3> contacto@econotrans.cl</h3>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3 col-sm-3 col-xs-3'>
                                <img src={phone1}
                                    className="img-fluid mx-2 mb-1" alt="rpa" />
                            </div>
                            <div className='col-md-9 col-sm-9 col-xs-9'>
                                <h4> Hablemos!</h4>
                                <h3><a href="tel:+569 94788521">+569 94788521</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 mt-5'>
                                <h4 className='text-center'>Mantente conectado</h4>
                            </div>
                            <div className='col-md-6 mt-5'>
                            <div className="d-flex justify-content-center">
                                <a className="nav-link mx-1 text-black" href="https://www.facebook.com/Econotransla" target="_blank"><i className="fab fa-facebook-f fa-2x"></i></a>
                                <a className="nav-link mx-1 text-black" href="https://www.instagram.com/econotrans_mudanzas/" target="_blank"><i className="fab fa-instagram fa-2x"></i></a>
                                <a className="nav-link mx-1 text-black" href="https://api.whatsapp.com/send?phone=56994788521" target="_blank"><i className="fab fa-whatsapp fa-2x"></i></a>
                            </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='col-md-6'>
                    <h3 className='mb-4 mt-5'>Nuestra Ubicación</h3>
                    <div className='mapa'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50668.31624488005!2d-72.38750914216885!3d-37.466157606804025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966bdd4669523657%3A0x8318f6229d680877!2zTG9zIEFuZ2VsZXMsIExvcyDDgW5nZWxlcywgQsOtbyBCw61v!5e0!3m2!1ses!2scl!4v1642721368943!5m2!1ses!2scl"
                            width="500"
                            height="400"
                            allowfullscreen=""
                            loading="lazy"
                        >
                        </iframe>
                    </div>

                </div>
            </div>
        </div>
    )
}
