import React from 'react';
import truck from '../../assets/truck2.jpg'
import perfil from '../../assets/perfil.jpg'
import foto from '../../assets/logo-letras.png'
import '../us/us.css'

export const Us = () => {
    return (
        <>
            <div className='mt-5'>
                <h6 className="repit">Fletes y Mudanzas - Todo Destino</h6>
                <div className="container mt-5">
                    <div className="row ">
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <br />
                            <img className="socies img-fluid animate__animated animate__fadeIn"
                                src={truck}
                                alt="aboutus"
                            />
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 animate__animated animate__backInLeft">
                            <h3 className="fw-bold mb-4 mt-3" >
                                Profesional, Efectiva y Seria
                            </h3>
                            <p className="common-text mb-4 " >
                                Nuestros servicios son convenientes. Los clientes destacan la competencia de nuestros trabajadores
                                y la calidad de la mudanza que prestamos.
                                En Econotrans destacamos por nuestra atención personalizada a cada cliente.
                                Somos una empresa cercana, con una alta adaptabilidad a los requerimientos de nuestros clientes.
                                <br /><br />

                            </p>
                            <br />
                            <div className='text-center'>
                                <img
                                    src={perfil}
                                    className="avatar rounded-circle mb-3"
                                    alt="avatar"
                                />

                                <h5 className="mb-2"><strong>Jaime Castillo Nuñez</strong></h5>
                                <p className="text-muted">CEO Econotrans</p>
                            </div>

                        </div >
                        <div className='mt-4'>
                            <h3>Grupo Empresarial</h3>
                        </div>
                        <div className='row text-center mt-4'>
                            <div className="col-md-6 pt-4 ">
                                <img className="empresa" src={foto} alt="..." />
                            </div>
                            <div className="col-md-6 pt-4 ">
                                <img className="empresa" src={foto} alt="..." />
                            </div>
                            <div className="col-md-6 pt-4 ">
                                <img className="empresa" src={foto} alt="..." />
                            </div>
                            <div className="col-md-6 pt-4 ">
                                <img className="empresa" src={foto} alt="..." />
                            </div>
                        </div>

                        <div className='mt-5 mb-4'>
                            <h3>Información Comercial</h3>
                        </div>

                        <div className='row'>
                            <div className='col-md-6 text-center mb-3'>
                                <div>
                                    R.U.N o R.U.T Empresa
                                </div>
                                <small class="text-muted">77052902-6</small>
                            </div>
                            <div className='col-md-6 text-center mb-3'>
                                <div>
                                    Principales clientes
                                </div>
                                <small class="text-muted">Particulares,
                                    Empresas e 
                                    Instituciones <br />(ej: archivos, museos, administraciones públicas, etc.)</small>
                            </div>
                            <div className='col-md-6 text-center mb-3'>
                                <div>
                                Características distintivas
                                </div>
                                <small class="text-muted">Disposición del equipo de trabajo y cuidado de los enceres. <br/> Limpieza y disponibilidad de nuestros vehículos.</small>
                            </div>
                            <div className='col-md-6 text-center mb-3'>
                                <div>
                                Datos necesarios para enviar un presupuesto
                                </div>
                                <small class="text-muted">Metros cuadrados de la casa,<br /> fecha del servicio, origen y destino</small>
                            </div>
                            <div className='col-md-6 text-center mb-3'>
                                <div>
                                Modalidad de pagos aceptada
                                </div>
                                <small class="text-muted">Transferencia bancaria <br/> Efectivo <br /> Debito</small>
                            </div>
                            <div className='col-md-6 text-center'>
                                <div>
                                Características distintivas
                                </div>
                                <small class="text-muted">Disposición del equipo de trabajo y cuidado de los enceres.<br /> Limpieza y disponibilidad de nuestros vehículos.</small>
                            </div>
                        </div>




                    </div>
                </div>
            </div>
        </>
    )
};
