import React from 'react'

export const Card = ({ images, title, title2, description }) => {
    return (

        <div className="animate__animated animate__zoomIn col-md-4 col-sm-12 col-xs-12 text-center">
            <div className="card h-100 ">
                <img src={images} className="card-img-top" alt="..." />
                <div className="card-body">
                    <h5 className="text card-title">{title}</h5>
                    <p className="text card-text">{description}</p>
                </div>
                <div className="card-footer">
                    <small className="footer">{title2}</small>
                </div>
            </div>
        </div>

    )
}
