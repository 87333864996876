import { Routes, Route, useLocation } from 'react-router-dom'
import { Carousel } from '../components/carousel/Carousel'
import { Road } from '../components/road/Road'
import { Contact } from '../components/contact/Contact'
import { Home } from '../components/home/Home'
import { Services } from '../components/services/Services'
import { Messenger } from '../components/ui/Messenger'

import { Navbar } from "../components/ui/Navbar"
import { Whatsapp } from '../components/ui/Whatsapp'
import { Us } from '../components/us/Us'

export const DashboardRoutes = () => {

    const location = useLocation()
    return (
        <>
            <Whatsapp />
            <Messenger />
            <Navbar />
            {
                (location.pathname === '/')
                    ? <div className='animate__animated animate__fadeInBottomLeft'><Carousel /></div>
                    : <div></div>
            }
            <div className="container">

                <Routes>
                    <Route path="home" element={<Home />} />
                    <Route path="road" element={<Road />} />
                    <Route path="services" element={<Services />} />
                    <Route path="us" element={<Us />} />
                    <Route path="contact" element={<Contact />} />

                    <Route path="/" element={<Home />} />
                </Routes>
            </div>
        </>
    )
}
