import React from 'react'
import icons from '../../assets/icon-trans.jpg'
import truck from '../../assets/truck1.jpg'
import foto from '../../assets/logo-letras.png'
import logo from '../../assets/logo-tras.png'
import './home.css'

export const Home = () => {

    return (

        <div>
            <h6 className="repit mt-5">Fletes y Mudanzas - Todo Destino</h6>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-12 pb-5">
                        <div className="row">
                            <div className="col-md-5 animate__animated animate__backInLeft">
                                <h3 className="fw-bold mb-4">
                                    Deposite su confianza<br />en nosotros
                                </h3>
                                <p className="common-text mb-4">
                                    Nuestros servicios son convenientes.
                                    Los clientes destacan la competencia de nuestros trabajadores
                                    y la calidad de la mudanza que prestamos.
                                    En Econotrans destacamos por nuestra atención personalizada a cada cliente.

                                </p>
                                <ul className="mt-4">
                                    <li className="d-flex iconos"><img src={icons}
                                        className="img-fluid mx-2 mb-1" alt="rpa" />
                                        <p className="parrafo"> Mudanzas Normales </p>
                                    </li>
                                    <br />
                                    <li className="d-flex iconos"><img src={icons}
                                        className="img-fluid mx-2 mb-1" alt="tecnologia" />
                                        <p className="parrafo"> Mudanzas Empresas u Oficinas </p>
                                    </li>
                                    <br />
                                    <li className="d-flex iconos"><img src={icons}
                                        className="img-fluid mx-2 mb-1" alt="gestión proyectos" />
                                        <p className="parrafo"> Fletes y Transportes de Carga </p>
                                    </li>
                                    <br />
                                    <li className="d-flex iconos"><img src={icons}
                                        className="img-fluid mx-2 mb-1" alt="desarrllo web mobile (móviles)" />
                                        <p className="parrafo"> Servicios de Embalaje y Encomiendas </p>
                                    </li>
                                    <br />
                                </ul>
                            </div>
                            <div className="col-md-7 animate__animated animate__backInRight text-center">
                                <img className="img-fluid mx-2 mb-1" src={truck} alt='camion' />
                                <img className="letras" src={foto} alt="..." />
                                <h2 className='text-center'>Mudanzas</h2>
                            </div>
                        </div>
                    </div>

                    {/* <-- Testimonios --> */}
                    <h3 className='mb-5'>Testimonios</h3>
                    <div id="carouselTestimonios" className="carousel  slide mb-5" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselTestimonios" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselTestimonios" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselTestimonios" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="3000">
                                {/* <-- Testimonio Uno --> */}
                                <div className='text-center'>

                                    <div className='clientes'>
                                        <p>
                                            Testimonio 1

                                        </p>
                                    </div>
                                    <div>
                                        <p> Nombre Cliente</p>
                                    </div>
                                    <div>
                                        <p> Cargo Cliente</p>
                                    </div>
                                    <div>
                                        <img className='logo-footer' src={logo} alt="logo" />
                                    </div>


                                </div>

                            </div>
                            <div className="carousel-item" data-bs-interval="2000">
                                {/* <-- Testimonio Dos --> */}
                                <div className='text-center'>

                                    <div className='clientes'>
                                        <p>
                                            Testimonio 2
                                        </p>
                                    </div>
                                    <div>
                                        <p> Nombre Cliente</p>
                                    </div>
                                    <div>
                                        <p> Cargo Cliente</p>
                                    </div>
                                    <div>
                                        <img className='logo-footer' src={logo} alt="logo" />
                                    </div>


                                </div>
                            </div>
                            <div className="carousel-item">
                                {/* <-- Testimonio Tres --> */}
                                <div className='text-center'>

                                    <div className='clientes'>
                                        <p>
                                            Testimonio 3

                                        </p>
                                    </div>
                                    <div>
                                        <p> Nombre Cliente</p>
                                    </div>
                                    <div>
                                        <p> Cargo Cliente</p>
                                    </div>
                                    <div>
                                        <img className='logo-footer' src={logo} alt="logo" />
                                    </div>


                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselTestimonios" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselTestimonios" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>




                </div>
            </div>
        </div>
    )
}
