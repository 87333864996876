import React from 'react'
import santiago from '../../assets/santiago.jpg'
import arica from '../../assets/arica.jpg'
import conce from '../../assets/conce.jpg'
import elei from '../../assets/elei.jpg'
import domicilio from '../../assets/domicilio.png'
import carga from '../../assets/carga.png'
import seguro from '../../assets/seguro.png'
import factura from '../../assets/factura.png'
import cerrado from '../../assets/cerrado.png'
import '../road/road.css'

export const Road = () => {

    return (
        <>
            <div className='container'>
                <h6 className="repit mt-5">Fletes y Mudanzas - Todo Destino</h6>
                <div className='row'>
                    <div className='mt-3 mb-3'>
                        <h3 className='text-center'>Rutas</h3>
                    </div>
                    <div className='col-md-12 col-sm-12 col-xs-12'>

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Los Ángeles - Santiago #1
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                    <div className="accordion-body">
                                        <div className='row text-center'>
                                            <div className='col-md-6 col-sm-12 col-xs-12 mt-4'>
                                                <h3 className='mb-3'>Distancia</h3>
                                                <p>5 h 31 min (512.6 km) por Ruta 5 S</p>
                                                <img className="santiago" src={santiago} alt="..." /><br />
                                                <a className="btn btn-primary mt-3" href="https://api.whatsapp.com/send?phone=56994788521" target="_blank" role="button">Cotiza Aquí</a>
                                            </div>
                                            <div className='col-md-6 col-sm-12 col-xs-12 mt-4'>
                                                <h3>Precios Económicos!</h3>
                                                <div className='mt-3'>
                                                    <ul >
                                                        <li className="d-flex mb-3">
                                                            <img src={carga} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Carga parcial o completa.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={domicilio} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Retiro y entrega en domicilio.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={cerrado} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Camión cerrado, capacidad 26m3.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={factura} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Contamos con Factura y boleta.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={seguro} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Seguro de carga hasta 1500UF.</p>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                        Los Ángeles - Arica #2
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                    <div className="accordion-body">
                                    <div className='row text-center'>
                                            <div className='col-md-6 col-sm-12 col-xs-12 mt-4'>
                                                <h3 className='mb-3'>Distancia</h3>
                                                <p>28 h (2,545.7 km) por Panamericana Norte/Ruta 5 S</p>
                                                <img className="santiago" src={arica} alt="..." /><br />
                                                <a className="btn btn-primary mt-3" href="https://api.whatsapp.com/send?phone=56994788521" target="_blank" role="button">Cotiza Aquí</a>
                                            </div>
                                            <div className='col-md-6 col-sm-12 col-xs-12 mt-4'>
                                                <h3>Precios Económicos!</h3>
                                                <div className='mt-3'>
                                                    <ul >
                                                        <li className="d-flex mb-3">
                                                            <img src={carga} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Carga parcial o completa.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={domicilio} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Retiro y entrega en domicilio.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={cerrado} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Camión cerrado, capacidad 26m3.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={factura} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Contamos con Factura y boleta.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={seguro} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Seguro de carga hasta 1500UF.</p>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                        Los Ángeles - Concepción #3
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                    <div className="accordion-body">
                                    <div className='row text-center'>
                                            <div className='col-md-6 col-sm-12 col-xs-12 mt-4'>
                                                <h3 className='mb-3'>Distancia</h3>
                                                <p>1 h 31 min (126.6 km) por Panamericana Sur/Ruta 5 S y Ruta 146</p>
                                                <img className="santiago" src={conce} alt="..." /><br />
                                                <a className="btn btn-primary mt-3" href="https://api.whatsapp.com/send?phone=56994788521" target="_blank" role="button">Cotiza Aquí</a>
                                            </div>
                                            <div className='col-md-6 col-sm-12 col-xs-12 mt-4'>
                                                <h3>Precios Económicos!</h3>
                                                <div className='mt-3'>
                                                    <ul >
                                                        <li className="d-flex mb-3">
                                                            <img src={carga} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Carga parcial o completa.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={domicilio} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Retiro y entrega en domicilio.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={cerrado} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Camión cerrado, capacidad 26m3.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={factura} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Contamos con Factura y boleta.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={seguro} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Seguro de carga hasta 1500UF.</p>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                                        Los Ángeles - Ruta Especial #4
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                                    <div className="accordion-body">
                                    <div className='row text-center'>
                                            <div className='col-md-6 col-sm-12 col-xs-12 mt-4'>
                                                <h3 className='mb-3'>Distancia</h3>
                                                <p>5 h 31 min (512.6 km) por Ruta 5 S</p>
                                                <img className="santiago" src={elei} alt="..." /><br />
                                                <a className="btn btn-primary mt-3" href="https://api.whatsapp.com/send?phone=56994788521" target="_blank" role="button">Cotiza Aquí</a>
                                            </div>
                                            <div className='col-md-6 col-sm-12 col-xs-12 mt-4'>
                                                <h3>Precios Económicos!</h3>
                                                <div className='mt-3'>
                                                    <ul >
                                                        <li className="d-flex mb-3">
                                                            <img src={carga} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Carga parcial o completa.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={domicilio} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Retiro y entrega en domicilio.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={cerrado} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Camión cerrado, capacidad 26m3.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={factura} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Contamos con Factura y boleta.</p>
                                                        </li>
                                                        <li className="d-flex mb-3">
                                                            <img src={seguro} className="img-fluid mx-2 mb-1" alt="rpa" />
                                                            <p >Seguro de carga hasta 1500UF.</p>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
