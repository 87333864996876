import React from 'react'
import { Card } from './Card'
import { YearsOld } from './YearsOld'
import medida from '../../assets/medida.png'
import experiencia from '../../assets/experiencia.png'
import cajas from '../../assets/cajas.png'
import foto from '../../assets/logo-letras.png'
import aduana from '../../assets/aduana.png'
import bodega from '../../assets/bodega.png'
import montaje from '../../assets/montaje.png'

export const Services = () => {
    return (
        <div className='mt-5'>
            <h6 className="repit">Fletes y Mudanzas - Todo Destino</h6>
            <YearsOld />
            <div className="row row-cols-1 row-cols-md-3 g-4">
                <Card images={medida} title="Servicios a medida" title2="Servicios" description="Nuestros servicios son convenientes. Los clientes destacan la competencia de nuestros trabajadores y la calidad de la mudanza que prestamos." />
                <Card images={experiencia} title="Nuestra Experiencia" title2="Experiencia" description="Nuestro personal esta capacitado y debidamente certificado, además de una basta experiencia en las rutas de nuestro país, así como también experiencias en el extranjero." />
                <Card images={cajas} title="Extras" title2="Otros Servicios" description="Tambien contamos con los servicios de Embalaje y Encomiendas con una alta adaptabilidad a los requerimientos de nuestros clientes." />
            </div>
            <div className='text-center mt-5'>
                <a className='title'> Servicios <img className="letras-contact" src={foto} alt="..." /></a>
            </div>
            <div className='container mt-4'>
                <div classname="row">
                    <div class="list-group">
                        <a class="list-group-item list-group-item-action flex-column align-items-start ">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Montaje y Desmontaje</h5>
                                <small>Disponible</small>
                            </div>
                            <p class="mb-1">Embalajes para vajilla y cristalería
                                Materiales para que el cliente empaquete las cajas
                                Carpinteros especializados en el montaje de muebles
                                Embalaje y preparación de los enseres del cliente</p>
                            <div className='text-center'>
                                <img className="servicios" src={montaje} alt="..." />
                            </div>
                            <small>Exclusivo Econotrans</small>
                        </a>
                        <a class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Bodegaje</h5>
                                <small class="text-muted">Disponible</small>
                            </div>
                            <p class="mb-1">Inventario de la mercancía depositada</p>
                            <div className='text-center'>
                                <img className="servicios" src={bodega} alt="..." />
                            </div>
                            <small class="text-muted">Exclusivo Econotrans</small>
                        </a>
                        <a class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Adicionales</h5>
                                <small class="text-muted">Disponible</small>
                            </div>
                            <p class="mb-1">Servicio de trámites de aduanas</p>
                            <div className='text-center'>
                                <img className="servicios" src={aduana} alt="..." />
                            </div>
                            <small class="text-muted">Exclusivo Econotrans</small>
                        </a>
                    </div>
                </div>






            </div>
        </div>
    )
}
