import React from 'react'
import foto from '../../assets/logo-letras.png'
import './services.css'

export const YearsOld = () => {
    return (
        <div>
            <section className="container-fluid heading-content">
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-4 pt-4 ">
                            <img className="empresa" src={foto} alt="..." />
                        </div>
                        <div className="col-md-8 pb-3">
                            <p className="common-text text-justify">
                                Es una empresa profesional, efectiva y seria.
                                Estamos en Los Ángeles, Bío Bío, y
                                nos deplazamos por las regiones de alrededor,
                                trasladando los enceres de tu hogar como si fueran nuestros,
                                preocupándonos de que lleguen siempre intactos al destino.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
