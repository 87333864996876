import React from "react";
import { NavLink } from "react-router-dom";
import logo1 from '../../assets/logo-large.png'
import logo2 from '../../assets/logo-tras.png'
import { Header } from './Header'
import "./navbar.css";

export const Navbar = () => {

    return (
      <>
        <Header />
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <a className="navbar_logo" href="/">
              <img src={logo1} className="logo1" />
              <img src={logo2} className="logo2" />
            </a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
                <div className="collapse navbar-collapse" id="navbarToggler">
                  <ul className="navbar-nav">
                    <li>
                      <NavLink to="/"><a className="fas fa-home"></a>Inicio </NavLink>
                    </li>
                    <li>
                      <NavLink to="/road"><a className="fas fa-shipping-fast"></a>Rutas</NavLink>
                    </li>
                    <li>
                      <NavLink to="/services"><a className="fas fa-truck-loading"></a>Servicios</NavLink>
                    </li>
                    <li>
                      <NavLink to="/us"><a className="fas fa-user-tie"></a>Nosotros</NavLink>
                    </li>
                    <li>
                      <NavLink to="/contact"><a className="fas fa-phone-volume"></a>Contacto</NavLink>
                    </li>
                  </ul>
                </div>
          </div>
        </nav>   
      </>
    )
}