import React from 'react'

export const Header = () => {
    return (
        <>
            <div className="blue-dark text-white">
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-md-5 col-sm-12 d-flex justify-content-center">
                            <a className="nav-link text-white mx-1 text-center" href="https://www.mundomudanzas.cl/empresas/econotrans" target="_blank"><i className="fab fa-maxcdn"></i></a>
                            <a className="nav-link text-white mx-1" href="https://amarillas.emol.com/empresa/32285/econotrans-mudanzas#profile" target="_blank"><i className="fab fa-amilia"></i></a>
                            <a className="nav-link text-white mx-1" href="https://www.facebook.com/Econotransla" target="_blank"><i className="fab fa-facebook-f"></i></a>
                            <a className="nav-link text-white mx-1" href="https://www.instagram.com/econotrans_mudanzas/" target="_blank"><i className="fab fa-instagram"></i></a>
                            <a className="nav-link text-white mx-1" href="https://api.whatsapp.com/send?phone=56994788521" target="_blank"><i className="fab fa-whatsapp"></i></a>
                        </div>
                        <div className="col-md-7 d-md-flex justify-content-center">
                            <a className="nav-link text-white text-center"><i className="fas fa-envelope-open-text"></i> contacto@econotrans.cl</a>
                            <a className="nav-link text-white text-center" href="tel:+569 94788521"><i className="fas fa-phone"></i> +569 94788521</a>
                        </div>
                    </div>
                </div>
            </div>   
        </>
    )
}
