import React from 'react'
import wsp from '../../assets/whatsapp.png'

export const Whatsapp = () => {
    return (
        <>
            <div className="btn-whatsapp">
                <a href="https://api.whatsapp.com/send?phone=56994788521" target="_blank">
                    <img className="img-fluid" src={wsp} alt="whatsapp" />
                </a>
            </div>
        </>
    )
}
